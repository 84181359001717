.button-link {
  z-index: 2;
  color: $home-color;
  border: 1px solid $home-color-light;
  text-align: center;
  background-color: transparent;
  padding: 15px 40px;
  transition: all 0.5s ease;
  display: inline-block;
  outline: none !important;
  letter-spacing: 1.5px;
  word-spacing: 3px;
  &:hover {
    color: $home-color;
    background-color: rgba($color: #ffffff, $alpha: 0.2);
    transform: translateY(-5px);
    cursor: pointer;

    .rotate-arrow {
      transform: rotate(90deg) !important;
      transition: 0.5s;
    }
  }
}
