#About {
  @media (min-width: $desktop-breakpoint-phone) {
    width: 85%;
    margin: auto;
  }
  .skill-set {
    background-color: $back-ground-2;
    box-shadow: 0 10px 30px -15px rgba(2, 12, 27, 0.7);
    padding: 40px;
    @media (min-width: $desktop-breakpoint-phone) {
      margin-right: -80px;
      margin-top: -50px;
      z-index: 2;
    }
    .skills-list {
      li {
        color: $home-color-light;
      }
    }
  }
}
.read-more {
  opacity: 0;
  font-size: $font-size-small;
  font-weight: 300;
  pointer-events: none;
  text-align: center;
  color: $text-color-2;
  transition: transform 300ms ease-out;
  font-style: italic;
  margin-top: 20px;
  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
}
.appear {
  pointer-events: initial;
  opacity: 1;
}
.about-wrapper {
  margin-top: -5px;
  background-color: $back-ground-2;
  overflow: hidden;
  min-height: 100vh;
}
.image-wrapper {
  display: block;
  position: relative;
  width: 200px;
  height: 200px;
  background-color: $home-color;
  border-radius: 5px;
  transition: all 300ms ease-out;
  margin: 30px auto;
  @media (min-width: $desktop-breakpoint-phone) {
    width: 250px;
    height: 250px;
  }
  &:hover,
  &:focus {
    background: transparent;
    outline: 0;
    &:after {
      top: 15px;
      left: 15px;
    }
    .profile-image {
      filter: none;
      mix-blend-mode: normal;
    }
  }
  .profile-image {
    width: 100%;
    z-index: 1;
    position: relative;
    mix-blend-mode: multiply;
    filter: grayscale(100%) contrast(1);
    border-radius: 5px;
  }
  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    transition: all 300ms ease-out;
  }
  &:before {
    top: 0;
    left: 0;
    background-color: $home-color-light;
    mix-blend-mode: screen;
    border-radius: 5px;
  }
  &:after {
    border: 2px solid $home-color-light;
    top: 20px;
    left: 20px;
    z-index: -1;
    border-radius: 5px;
  }
}

.bar-main {
  border: 2px solid $home-color-light;
}
.skill,
.percent {
  color: $home-color-light;
  font-size: $font-size-xsmall;
  font-weight: bold;
  @media (min-width: $desktop-breakpoint-phone) {
    font-size: $font-size-small;
    font-weight: lighter;
  }
}
.percent {
  animation: lighten 400ms ease-out 3s 2;
}
.about-list {
  color: $home-color-light;
  font-weight: lighter;
}

.bar {
  width: 100%;
}

.progress {
  background-color: $back-ground-2;
  padding: $xs-size;
  width: 0%;
  transition: 3s ease-out;
}

.bar-90 {
  width: 100%;
}
.bar-80 {
  width: 90%;
}
.bar-70 {
  width: 80%;
}
.bar-60 {
  width: 70%;
}
.bar-50 {
  width: 60%;
}

@keyframes lighten {
  0% {
    color: $home-color-light;
  }
  100% {
    color: white;
  }
}
