#Experience {
  margin-top: 100px;
  @media (min-width: $desktop-breakpoint-phone) {
    width: 75%;
    margin: auto;
    margin-top: 150px;
  }
  .option-wrapper {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 20px;
    position: relative;
    overflow-x: scroll;
    overflow-y: hidden;
    @media (min-width: $desktop-breakpoint-laptop) {
      overflow-x: scroll;
      overflow-y: hidden;
      // width: 75%;
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: 39px;
        background-color: $back-ground-2;
      }
    }
    .option-item {
      display: inline-block;
      margin-right: 30px;
      font-size: $font-size-small;
      margin-bottom: 40px;
      padding: 15px;
      position: relative;
      transition: all 300ms ease-out;
      white-space: nowrap;
      @media (min-width: $desktop-breakpoint-phone) {
        padding: 25px 50px;
        margin-top: 10px;
        margin-right: 7px;
      }
      &:hover {
        color: $home-color;
        cursor: pointer;
        transform: scale(1.05);
      }
      &:after {
        content: "";
        position: absolute;
        height: 3px;
        width: 0;
        bottom: 0;
        left: 0;
        background-color: $home-color-light;
        transition: width 500ms ease-out;
      }
    }
    .option-item.active-item {
      color: $home-color;
      transform: scale(1.05);
      background-color: $back-ground-2;
      &:after {
        width: 100%;
      }
    }
  }
  .content-wrapper {
    .title-company {
      font-weight: bold;

      .title {
        font-size: $font-size-large;
        color: $text-color-2;
        display: block;
        @media (min-width: $desktop-breakpoint-laptop) {
          display: inline-block;
        }
      }
    }
    .location-time {
      font-family: $font-title;
      .timeline {
        color: $text-color-2;
      }
    }
    .description {
      p {
        position: relative;
        padding-left: 30px;
        @media (min-width: $desktop-breakpoint-laptop) {
          padding: 0 60px;
        }
        &::before {
          content: "";
          height: 10px;
          width: 10px;
          position: absolute;
          left: 10px;
          top: 10px;
          background-color: $home-color-light;
          @media (min-width: $desktop-breakpoint-laptop) {
            left: 30px;
          }
        }
      }
    }
  }
}

// @keyframes goRight {
//   0% {
//     width: 0;
//   }
//   100% {
//     width: 110%;
//   }
// }
