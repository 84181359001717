.home-wrapper {
  min-height: 100vh;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: $desktop-breakpoint-laptop) {
    display: block;
  }
}

.media-wrapper {
  display: none;
  @media (min-width: $desktop-breakpoint-phone) {
    display: initial;
    position: fixed;
    top: 25%;
    right: 30px;
  }

  i {
    margin-top: 2rem;
    color: $text-color-2;
    font-size: $font-size-xlarge;
    transition: all 0.5s ease;
    @media (max-width: $desktop-breakpoint-phone) {
      font-size: $font-size-xlarge;
    }

    &:hover {
      color: $home-color;
      transform: scale(1.2) translateX(-5px);
    }
  }
}
