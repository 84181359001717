.go-back-button {
  color: $text-color-2;
  position: relative;
  top: 50px;
  left: 25px;
  transition: transform 300ms ease-out;
  padding: 10px;
  border-radius: 50%;
  outline: none;
  border: 1px solid $home-color-light;
  display: inline-block;
  @media (min-width: $desktop-breakpoint-laptop) {
    position: fixed;
    top: 30%;
    left: 70px;
  }

  &:hover {
    color: $home-color;
    transform: scale(1.1);
  }
  .svg-wrapper {
    color: $text-color-2;
  }
  svg {
    width: 30px;
    transition: all 300ms ease;
    height: 30px;
  }
}
.go-top-button {
  display: inline-block;
  margin-top: 100px;
  &:hover {
    .circle {
      color: $home-color-light;
      border: 1px solid $home-color;
      transform: scale(1.05);
    }
  }
  @media (min-width: $desktop-breakpoint-laptop) {
  }
  .circle {
    display: inline-block;
    transition: all 300ms ease-out;
    padding: 10px;
    border-radius: 50%;
    outline: none;
    border: 1px solid $home-color-light;
  }
  svg {
    width: 30px;
    transition: all 300ms ease;
    height: 30px;
    transform: rotate(90deg);
  }
}
.project-details-wrapper {
  margin: auto;
  max-width: 900px;
  margin-top: 100px;
  padding: 25px;
  margin-bottom: 200px;
  @media (min-width: $desktop-breakpoint-phone) {
    width: 70%;
    padding: 0;
  }
  @media (min-width: $desktop-breakpoint-laptop) {
    width: 60%;
    margin-top: 100px;
    margin-bottom: 150px;
  }

  .project-title {
    font-size: $font-size-xlarge;
    font-weight: bold;
    font-family: $font-title;
    @media (min-width: $desktop-breakpoint-phone) {
      font-size: $font-size-title;
    }
  }
  .project-detail-summary {
    margin: 30px auto;
    padding: 15px;

    color: $home-color-light;
    background-color: $back-ground-2;
    @media (min-width: $desktop-breakpoint-phone) {
      padding: 25px;
      width: 90%;
    }
  }
  .project-description {
    margin-bottom: 30px;
  }

  .tech-inner-wrapper {
    @media (min-width: $desktop-breakpoint-laptop) {
      display: flex;
    }
    @media (min-width: $desktop-breakpoint-laptop) {
    }
    .project-tech-title {
      font-size: $font-size-small;
      text-transform: uppercase;
      color: $home-color-light;
      font-weight: bold;
      position: relative;
      margin-bottom: 20px;
    }
    .tech-wrapper-1 {
      padding: 15px;
      margin-top: 30px;
      @media (min-width: $desktop-breakpoint-phone) {
        padding: 25px;
        padding-bottom: 70px;
        width: 70%;
      }
      @media (min-width: $desktop-breakpoint-laptop) {
        width: 60%;
        padding: 40px;
      }
      background-color: $back-ground-2;
      .item {
        position: relative;
        padding-left: 20px;
        margin-top: 15px;
        &::before {
          content: "";
          position: absolute;
          width: 10px;
          height: 10px;
          left: 0;
          top: 5px;
          background-color: $home-color-light;
        }
      }
    }
    .tech-wrapper-2 {
      margin-top: 30px;
      @media (min-width: $desktop-breakpoint-phone) {
        margin-left: auto;
        width: 70%;
        margin-top: -40px;
      }
      @media (min-width: $desktop-breakpoint-laptop) {
        width: 40%;
        margin-top: -20px;
      }
      .tech-inner-wrapper-2 {
        padding: 25px;
        background-color: rgba($color: $back-ground-1, $alpha: 0.5);
        border: 1px solid rgba($color: $text-color-2, $alpha: 0.2);
        @media (min-width: $desktop-breakpoint-laptop) {
          margin-left: -40px;
        }
        .list {
          columns: 2;
          a {
            display: block;
            color: $text-color-2;
            font-family: $font-title;
            font-size: $font-size-small;
          }
        }
      }
    }
  }
}
