@import "./setting";
html {
  font-size: 62.5%;
  overflow-x: hidden;
}
body {
  font-family: $font-text;
  font-size: $font-size-medium;
  overflow-x: hidden;
  background-color: #071528;
  color: $text-color-1;
  letter-spacing: 1px;
  word-spacing: 2px;
}
body.nav-open {
  @media (max-width: $desktop-breakpoint-phone) {
    position: fixed;
  }
}
.highlight-text {
  color: $home-color;
}
.portfolio {
  font-weight: bold;
  color: $home-color;
  display: inline-block;
  transition: all 0.3s ease-out;
  font-family: $font-title;
  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
}

.main-wrapper {
  width: 100%;
  padding: 0 25px;
  margin: auto;
  @media (min-width: $desktop-breakpoint-phone) {
    padding: 0 75px;
  }
  @media (min-width: $desktop-breakpoint-laptop) {
    padding: 0;
    max-width: 1000px;
  }
  @media (min-width: $desktop-breakpoint-biglaptop) {
    max-width: 1400px;
  }
  .section-title {
    font-size: $font-size-xlarge;
    color: $text-color-2;
    font-weight: bold;
    font-family: $font-title;
    position: relative;
    display: block;
    @media (min-width: $desktop-breakpoint-laptop) {
      font-size: $font-size-title;
    }
    .highlight-text {
      font-size: $font-size-medium;
      margin-right: 10px;
      font-weight: normal;
    }
    &::after {
      content: "";
      position: absolute;
      width: 60%;
      height: 1px;
      top: 40px;
      left: 40px;
      background-color: $text-color-2;
      opacity: 0.3;
      @media (min-width: $desktop-breakpoint-laptop) {
        top: 50px;
      }
    }
  }
}

@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.page-enter {
  animation: fadeIn 1s forwards;
}

.page-exit {
  animation: fadeOut 1s forwards;
}
