.feature-card {
  display: block;
  position: relative;
  min-height: 60vh;
  margin-top: 60px;
  margin-bottom: 100px;
  @media (min-width: $desktop-breakpoint-phone) {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 100px;
    margin-bottom: 0;
  }
  .column-1,
  .column-2 {
    width: 100%;
    @media (min-width: $desktop-breakpoint-phone) {
      width: 50%;
    }
  }

  .column-1 {
    position: relative;
    @media (min-width: $desktop-breakpoint-laptop) {
    }

    .feature-image-wrapper {
      background-color: $home-color;
      opacity: 0.1;
      transition: all 300ms ease-out;
      .links {
        pointer-events: none;
      }
      @media (min-width: $desktop-breakpoint-phone) {
        transform: scale(1.2);
        opacity: 0.4;
      }
      @media (min-width: $desktop-breakpoint-laptop) {
        opacity: 0.4;
        .links {
          pointer-events: initial;
        }
        &:hover {
          transform: scale(1.17);
          background-color: transparent;
          opacity: 0.9;
          img {
            mix-blend-mode: multiply;
            filter: none;
          }
        }
      }
    }
    img {
      width: 100%;
      mix-blend-mode: multiply;
      filter: grayscale(100%) contrast(1);
      @media (min-width: $desktop-breakpoint-laptop) {
      }
    }
  }
  .column-2 {
    position: absolute;
    top: 40px;
    padding: 0 25px;
    @media (min-width: $desktop-breakpoint-phone) {
      position: relative;
      top: 0;
    }
    .feature-card-content {
      .project-name {
        font-size: $font-size-large;
        color: $text-color-2;
        margin: 15px 0;
        font-weight: bold;
      }
      .project-sammary {
        margin-bottom: auto;
        font-size: $font-size-medium;
        background-color: $back-ground-2;
        padding: 20px;
        border-radius: 5px;
      }
      .project-tech {
        border-top: 1px dotted rgba($color: $home-color, $alpha: 0.2);
        margin-top: 25px;
        padding-top: 20px;
        font-size: $font-size-medium;
        font-family: $font-title;
      }
      .icon-wrapper {
        display: flex;

        margin-top: 15px;
        &.left {
          justify-content: flex-end;
        }
        &.right {
          justify-content: flex-start;
        }
      }
      .git-icon {
        width: 20px;
        height: 20px;
        color: $text-color-1;
        transition: transform 300ms ease;
        display: inline-block;
        margin-right: 30px;
        &:hover {
          transform: scale(1.05);
          color: $home-color;
        }
      }

      .icon {
        display: inline-block;
        margin-top: 3px;
        .detail {
          width: 20px;
          height: 20px;
          transition: all 300ms ease-out;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' data-prefix='fas' data-icon='external-link-alt' class='svg-inline--fa fa-external-link-alt fa-w-16' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%238D8EA9' d='M432 320h-32a16 16 0 00-16 16v112H64V128h144a16 16 0 0016-16V80a16 16 0 00-16-16H48a48 48 0 00-48 48v352a48 48 0 0048 48h352a48 48 0 0048-48V336a16 16 0 00-16-16zM488 0H360c-21.37 0-32.05 25.91-17 41l35.73 35.73L135 320.37a24 24 0 000 34L157.67 377a24 24 0 0034 0l243.61-243.68L471 169c15 15 41 4.5 41-17V24a24 24 0 00-24-24z'/%3E%3C/svg%3E");
          &:hover {
            transform: scale(1.05);
            background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' data-prefix='fas' data-icon='external-link-alt' class='svg-inline--fa fa-external-link-alt fa-w-16' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%2364FFDA' d='M432 320h-32a16 16 0 00-16 16v112H64V128h144a16 16 0 0016-16V80a16 16 0 00-16-16H48a48 48 0 00-48 48v352a48 48 0 0048 48h352a48 48 0 0048-48V336a16 16 0 00-16-16zM488 0H360c-21.37 0-32.05 25.91-17 41l35.73 35.73L135 320.37a24 24 0 000 34L157.67 377a24 24 0 0034 0l243.61-243.68L471 169c15 15 41 4.5 41-17V24a24 24 0 00-24-24z'/%3E%3C/svg%3E");
          }
        }
      }
    }
  }
}
